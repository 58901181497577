import React, { useContext, useState } from 'react';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TableContext } from '../context';

/**
 * Time component for selecting and displaying time.
 * Provides options to select predefined times or use a date picker.
 */
export default function Time() {
    const { getCurrentFormattedDateTime, selectedTime, setSelectedTime, setLoading } = useContext(TableContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [temporaryDatePickerTime, setTemporaryDatePickerTime] = useState(DateTime.fromJSDate(selectedTime).toISO());
    const [temporaryTimeOptionTime, setTemporaryTimeOptionTime] = useState(DateTime.fromJSDate(selectedTime).toISO());
    const [showSetButton, setShowSetButton] = useState(false);
    const [lastChanged, setLastChanged] = useState(''); // Track which time was last changed

    /**
     * Handles the change of date from the date picker.
     * @param {DateTime} date - The selected date.
     */
    const handleDateChange = (date) => {
        const utcDate = date.toUTC().toJSDate();
        setTemporaryDatePickerTime(date.toISO());
        setSelectedTime(utcDate);
        setLoading(true);
        setIsDropdownOpen(false); // Close the dropdown after setting the value
        setShowSetButton(false); // Hide the set button after setting the value
        setLastChanged('datePicker'); // Update last changed to datePicker
    };

    /**
     * Handles the selection of a predefined time option.
     * @param {string} value - The selected time value.
     */
    const handleTimeOptionClick = (value) => {
        const selectedDate = new Date(value);
        const utcDate = new Date(Date.UTC(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            selectedDate.getHours(),
            selectedDate.getMinutes(),
            selectedDate.getSeconds()
        ));
        setTemporaryTimeOptionTime(utcDate.toISOString());
        setSelectedTime(utcDate);
        setLoading(true);
        setIsDropdownOpen(false); // Close the dropdown after setting the value
        setLastChanged('timeOption'); // Update last changed to timeOption
    };

    // Predefined time options
    const timeOptions = [
        { label: "Current Time", value: getCurrentFormattedDateTime(new Date(Date.now())) },
        { label: "Before 5 mins", value: getCurrentFormattedDateTime(new Date(Date.now() - 5 * 60000)) },
        { label: "Before 10 mins", value: getCurrentFormattedDateTime(new Date(Date.now() - 10 * 60000)) },
        { label: "Before 25 mins", value: getCurrentFormattedDateTime(new Date(Date.now() - 25 * 60000)) },
        { label: "Before 30 mins", value: getCurrentFormattedDateTime(new Date(Date.now() - 30 * 60000)) },
        { label: "Before 45 mins", value: getCurrentFormattedDateTime(new Date(Date.now() - 45 * 60000)) },
        { label: "Before hour", value: getCurrentFormattedDateTime(new Date(Date.now() - 60 * 60000)) },
        { label: "Before 2 hours", value: getCurrentFormattedDateTime(new Date(Date.now() - 120 * 60000)) },
        { label: "Before 3 hours", value: getCurrentFormattedDateTime(new Date(Date.now() - 180 * 60000)) },
        { label: "Before 4 hours", value: getCurrentFormattedDateTime(new Date(Date.now() - 240 * 60000)) },
        { label: "Before 6 hours", value: getCurrentFormattedDateTime(new Date(Date.now() - 360 * 60000)) },
        { label: "Before 12 hours", value: getCurrentFormattedDateTime(new Date(Date.now() - 720 * 60000)) },
        { label: "Before day", value: getCurrentFormattedDateTime(new Date(Date.now() - 1440 * 60000)) },
        { label: "Before 2 days", value: getCurrentFormattedDateTime(new Date(Date.now() - 2880 * 60000)) },
        { label: "Before 3 days", value: getCurrentFormattedDateTime(new Date(Date.now() - 4320 * 60000)) },
        { label: "Before 4 days", value: getCurrentFormattedDateTime(new Date(Date.now() - 5760 * 60000)) },
        { label: "Before 5 days", value: getCurrentFormattedDateTime(new Date(Date.now() - 7200 * 60000)) },
        { label: "Before week", value: getCurrentFormattedDateTime(new Date(Date.now() - 10080 * 60000)) },
        { label: "Before 2 weeks", value: getCurrentFormattedDateTime(new Date(Date.now() - 20160 * 60000)) },
        { label: "Before month", value: getCurrentFormattedDateTime(new Date(Date.now() - 43200 * 60000)) },
        { label: "After 5 mins", value: getCurrentFormattedDateTime(new Date(Date.now() + 5 * 60000)) },
        { label: "After 10 mins", value: getCurrentFormattedDateTime(new Date(Date.now() + 10 * 60000)) },
        { label: "After 25 mins", value: getCurrentFormattedDateTime(new Date(Date.now() + 25 * 60000)) },
        { label: "After 30 mins", value: getCurrentFormattedDateTime(new Date(Date.now() + 30 * 60000)) },
        { label: "After 45 mins", value: getCurrentFormattedDateTime(new Date(Date.now() + 45 * 60000)) },
        { label: "After hour", value: getCurrentFormattedDateTime(new Date(Date.now() + 60 * 60000)) },
        { label: "After 2 hours", value: getCurrentFormattedDateTime(new Date(Date.now() + 120 * 60000)) },
        { label: "After 3 hours", value: getCurrentFormattedDateTime(new Date(Date.now() + 180 * 60000)) },
        { label: "After 4 hours", value: getCurrentFormattedDateTime(new Date(Date.now() + 240 * 60000)) },
        { label: "After 6 hours", value: getCurrentFormattedDateTime(new Date(Date.now() + 360 * 60000)) },
        { label: "After 12 hours", value: getCurrentFormattedDateTime(new Date(Date.now() + 720 * 60000)) },
        { label: "After day", value: getCurrentFormattedDateTime(new Date(Date.now() + 1440 * 60000)) },
        { label: "After 2 days", value: getCurrentFormattedDateTime(new Date(Date.now() + 2880 * 60000)) },
        { label: "After 3 days", value: getCurrentFormattedDateTime(new Date(Date.now() + 4320 * 60000)) },
        { label: "After 4 days", value: getCurrentFormattedDateTime(new Date(Date.now() + 5760 * 60000)) },
        { label: "After 5 days", value: getCurrentFormattedDateTime(new Date(Date.now() + 7200 * 60000)) },
        { label: "After week", value: getCurrentFormattedDateTime(new Date(Date.now() + 10080 * 60000)) },
        { label: "After 2 weeks", value: getCurrentFormattedDateTime(new Date(Date.now() + 20160 * 60000)) },
        { label: "After month", value: getCurrentFormattedDateTime(new Date(Date.now() + 43200 * 60000)) },
    ];

    // Determine which time to display based on the last changed input
    const displayTime = lastChanged === 'datePicker' ? temporaryDatePickerTime : temporaryTimeOptionTime;

    return (
        <div style={{ position: 'relative', width: 'fit-content' }}>
            <div 
                style={{ 
                    width: '25vw', 
                    height: "3vh", 
                    display: 'flex', 
                    alignItems: 'center', 
                    cursor: 'pointer', 
                    justifyContent: 'space-between', 
                    border: '1px solid black',
                    padding: '10px',
                    borderRadius: '5px' // Add slightly rounded borders
                }}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                <span> Time: {DateTime.fromISO(displayTime, { zone: 'UTC' }).toFormat('dd-MM-yyyy HH:mm:ss')} UTC</span>
                <span style={{ 
                    marginLeft: 'auto', 
                    paddingLeft: '10px', 
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)', 
                    transition: 'transform 0.3s ease',
                    color: '#42b0d5' 
                }}>▼</span>
            </div>

            {isDropdownOpen && (
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    width: "100%", // Increase width of dropdown
                    justifyContent: 'space-between',
                    height: '25vh', // Set height of dropdown
                }}>
                    <div style={{ marginRight: '10px', padding: '5px', width: "60%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='en-gb'>
                        <DateTimePicker
                            value={DateTime.fromISO(temporaryDatePickerTime, { zone: 'UTC' })}
                            onChange={(date) => {
                                setTemporaryDatePickerTime(date.toISO());
                                setShowSetButton(true); // Show the set button when date changes
                            }}
                            renderInput={(params) => (
                                <input 
                                {...params} 
                                value={DateTime.fromISO(temporaryDatePickerTime, { zone: 'UTC' })}
                                />
                            )}
                        />
                        </LocalizationProvider>
                        {showSetButton && (
                            <button 
                                style={{
                                    marginTop: '10px', 
                                    padding: '5px 25px', 
                                    cursor: 'pointer', 
                                    backgroundColor: 'rgb(66, 176, 213)', 
                                    color: 'white', 
                                    border: 'none', 
                                    borderRadius: '5px' // Optional: add rounded corners
                                }}
                                onClick={() => handleDateChange(DateTime.fromISO(temporaryDatePickerTime))}
                            >
                                Set Time
                            </button>
                        )}
                    </div>

                    {/* Divider line */}
                    <div style={{ width: '1px', backgroundColor: '#ddd', margin: '10px' }}></div>

                    {/* Time options on the right */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: "40%", overflowY: 'auto' }}>
                        {timeOptions.map((option, index) => (
                            <div 
                                key={index}
                                style={{ padding: '5px', cursor: 'pointer', borderBottom: '1px solid #ddd' }}
                                onClick={() => handleTimeOptionClick(option.value)}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}