import React, { useContext, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import Logo from "../assets/Maersk_Logo_RGB.svg";
import "../App.css";
import { TableContext } from "../context";

/**
 * Navbar component for displaying the navigation bar.
 * Includes the company logo, portal link, and user profile information.
 */
function Navbar() {
  const { graphData, handleLogout } = useContext(TableContext);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  /**
   * Toggles the dropdown open or closed.
   */
  const handleDropdownToggle = () => {
    console.log("Dropdown toggle clicked");
    console.log("isDropdownOpen", isDropdownOpen);
    setDropdownOpen(!isDropdownOpen);
  };

  /**
   * Closes the dropdown.
   */
  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  /**
   * Handles the click outside event for the dropdown.
   * Closes the dropdown if the click is outside the dropdown element.
   *
   * @param {Event} event - The click event.
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleDropdownClose();
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    // Cleanup function for the effect hook
    return () => {
      // Remove the event listener for mousedown
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // This effect hook runs when isDropdownOpen changes
  }, [isDropdownOpen]);

  return (
    <nav className="navbar">
      <div className="nav-bar">
        <div className="nav-container">
          <img 
            src={Logo} 
            alt="Logo"
            height="50"
            width="120"
            className="navbar-logo" 
          />

          <span className="logo-navLogo">
            <Link to="/">FLUX</Link>
            <p></p>
          </span>
        </div>
        <div
          ref={dropdownRef}
          className="userProfile"
          onClick={handleDropdownToggle}
          style={{ position: 'relative' }} // Ensure the parent is relatively positioned
        >
          <span>{graphData?.displayName}</span>
          <ProfilePicture
            initials={graphData?.displayName
              .match(/(^\S\S?|\b\S)?/g)
              .join("")
              .match(/(^\S|\S$)?/g)
              .join("")
              .toUpperCase()}
            size={35}
          />
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <ul>
                <li>
                  <Link onClick={handleLogout}>Logout</Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;