import React from 'react';
 
const ProfilePicture = ({ initials, size }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        background: '#007bff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
        fontSize: size / 2,
      }}
    >
      {initials}
    </div>
  );
};
 
export default ProfilePicture;